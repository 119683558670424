import { Divider, Typography } from "@material-ui/core";
import { Inline, Stack, useUID } from "@superdispatch/ui";
import { Box } from "@superdispatch/ui-lab";
import { LooseItems } from "bol/core/LooseItems";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { InspectionEmptyIcon } from "shared/eta/icons/InspectionEmptyIcon";
import styled from "styled-components";

import { AIAGBOLDTO, AIAGVehicleDTO } from "../data/AIAGOnlineBOLDTO";
import { AIAGDamage } from "./AIAGDamage";
import { AIAGLooseItems, hasLooseItem } from "./AIAGLooseItems";

const Wrapper = styled(Box)`
  &:not(:last-child) > div {
    border-bottom: 1px solid #dfe0e2;
    padding-bottom: 32px;
  }
`;

function EmptyInspectionContent({ title }: { title: string }) {
  return (
    <Stack space="medium">
      <Typography variant="h5">{title}</Typography>
      <Stack space="small" align="center">
        <InspectionEmptyIcon />
        <Typography color="textSecondary">
          This section shows inspection details once they are added
        </Typography>
      </Stack>
    </Stack>
  );
}

interface VehicleProps {
  data: AIAGVehicleDTO;
  status: AIAGBOLDTO["status"];
}

export const AIAGVehicle = ({ data, status }: VehicleProps) => {
  const uid = useUID();

  const pickupInspection = useMemo(
    () =>
      data.aiag_inspections.find((inspection) => inspection.step === "pickup"),
    [data.aiag_inspections],
  );
  const deliveryInspection = useMemo(
    () =>
      data.aiag_inspections.find(
        (inspection) => inspection.step === "delivery",
      ),
    [data.aiag_inspections],
  );

  const hasAdditionalPickupInspection = useMemo(() => {
    if (!pickupInspection) {
      return false;
    }
    return (
      hasLooseItem(pickupInspection) ||
      !!data.odometer_reading ||
      !!data.pickup_inspection_notes
    );
  }, [pickupInspection, data.odometer_reading, data.pickup_inspection_notes]);

  const hasAdditionalDeliveryInspection = useMemo(() => {
    if (!deliveryInspection) {
      return false;
    }
    return (
      hasLooseItem(deliveryInspection) ||
      !!data.delivery_odometer_reading ||
      !!data.delivery_inspection_notes
    );
  }, [
    deliveryInspection,
    data.delivery_odometer_reading,
    data.delivery_inspection_notes,
  ]);

  return (
    <Wrapper>
      <Box aria-labelledby={uid} margin="medium">
        <Stack space="small">
          <Stack space="none">
            <Inline space="xsmall" verticalAlign="center">
              <Typography variant="h4" id={uid}>
                {data.year} {data.make} {data.model}
              </Typography>
              <Typography color="textSecondary">
                {data.color ? capitalize(data.color) : null} {data.type}
              </Typography>
            </Inline>
            <Typography color="textSecondary">{data.vin}</Typography>
          </Stack>

          <Divider />

          <Stack>
            {hasAdditionalPickupInspection && (
              <>
                <Typography variant="h5">
                  Pickup Additional Inspection
                </Typography>
                {!!data.odometer_reading && (
                  <Inline>
                    <Typography color="textSecondary">Odometer:</Typography>
                    <Typography>{data.odometer_reading}</Typography>
                  </Inline>
                )}
                {!!data.pickup_inspection_notes && (
                  <Inline>
                    <Typography color="textSecondary">Notes:</Typography>
                    <Typography aria-label="pickup inspection notes">
                      {data.pickup_inspection_notes}
                    </Typography>
                  </Inline>
                )}

                {pickupInspection && (
                  <AIAGLooseItems
                    looseItems={{
                      type: pickupInspection.step,
                      ...pickupInspection,
                    }}
                  />
                )}
              </>
            )}

            {pickupInspection ||
            status === "Picked up" ||
            status === "Delivered" ? (
              <Box aria-label="pickup damages">
                <Stack>
                  <Typography variant="h5">Pickup Damages</Typography>
                  {pickupInspection?.damages.length ? (
                    pickupInspection?.damages.map((damage, i) => (
                      <AIAGDamage {...damage} key={i} />
                    ))
                  ) : (
                    <Typography color="textSecondary">No Damages</Typography>
                  )}
                </Stack>
              </Box>
            ) : (
              <EmptyInspectionContent title="Pickup Inspection" />
            )}
          </Stack>

          <Divider />

          <Stack>
            {hasAdditionalDeliveryInspection && (
              <>
                <Typography variant="h5">Delivery Inspection</Typography>
                {!!data.delivery_odometer_reading && (
                  <Inline>
                    <Typography color="textSecondary">Odometer:</Typography>
                    <Typography>{data.delivery_odometer_reading}</Typography>
                  </Inline>
                )}
                {!!data.delivery_inspection_notes && (
                  <Inline>
                    <Typography color="textSecondary">Notes:</Typography>
                    <Typography aria-label="delivery inspection notes">
                      {data.delivery_inspection_notes}
                    </Typography>
                  </Inline>
                )}

                {deliveryInspection && (
                  <AIAGLooseItems
                    looseItems={{
                      type: deliveryInspection.step,
                      ...deliveryInspection,
                    }}
                  />
                )}
              </>
            )}

            {deliveryInspection || status === "Delivered" ? (
              <Box aria-label="delivery damages">
                <Stack>
                  <Typography variant="h5">Delivery Damages</Typography>
                  {deliveryInspection?.damages.length ? (
                    deliveryInspection.damages.map((damage, i) => (
                      <AIAGDamage {...damage} key={i} />
                    ))
                  ) : (
                    <Typography color="textSecondary">No Damages</Typography>
                  )}
                </Stack>
              </Box>
            ) : (
              <EmptyInspectionContent title="Delivery Inspection" />
            )}
          </Stack>
        </Stack>
      </Box>
    </Wrapper>
  );
};
