import { Column, Columns, Inline, Stack } from "@superdispatch/ui";
import styled from "styled-components";

import { DamageDTO } from "../data/AIAGOnlineBOLDTO";
import { PhotoGridAIAG } from "./photos/PhotoGridAIAG";

const Wrapper = styled.div`
  margin-bottom: 20px;
`;
const DamageCode = styled.span`
  font-weight: 500;
  font-size: 20px;
`;

export function AIAGDamage({ area, severity, type, photos }: DamageDTO) {
  return (
    <Wrapper>
      <Stack>
        <Columns align="center" space="small">
          <Column width="content">
            <Inline space="xsmall">
              <DamageCode>{area.code}</DamageCode>
              <DamageCode>{type.code}</DamageCode>
              <DamageCode>{severity.code}</DamageCode>
            </Inline>
          </Column>
          <Column>
            <span>{area.description}</span> <span>{type.description}</span>
            {" - "}
            <span>{severity.description}</span>
          </Column>
        </Columns>
        <PhotoGridAIAG photos={photos} />
      </Stack>
    </Wrapper>
  );
}
