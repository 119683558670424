import "./photo-viewer-toolbar-style.css";
import { useState } from "react";
import styled from "styled-components";
import { DamagePhotoDTO } from "../../data/AIAGOnlineBOLDTO";
import { LightboxGallery } from "shared/components/LightboxGallery";
import { parsePhotoSlide } from "shared/utils/mediaUtils";

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 128px);
  grid-auto-rows: 96px;
  grid-gap: 10px;
  min-width: unset;
`;
export const GridItem = styled.div<{ size: "large" | "medium" | "default" }>`
  background-color: #e1e1e1;
  transition: 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
    cursor: zoom-in;
  }
  ${({ size }) =>
    size === "large" &&
    `
    grid-column: 1 / 4;
    grid-row: 1 / 4;
    `}
  ${({ size }) =>
    size === "medium" &&
    `
    grid-column: 4 / 6;
    grid-row: 1 / 3;
    `}
`;
export const GridImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  align-self: center;
  display: flex;
`;
export const NoPhotosText = styled.div`
  color: #848484;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
`;

interface PhotoGridProps {
  photos: DamagePhotoDTO[];
}

export const PhotoGridAIAG = ({ photos }: PhotoGridProps) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(-1);
  const slides = photos
    .map((photo) => photo.original_photo_url)
    .map((photo) => parsePhotoSlide(photo, undefined, false));

  if (photos.length === 0) {
    return <NoPhotosText>NO PHOTOS</NoPhotosText>;
  }

  const handleImageClick = (index: number) => {
    setCurrentSlideIndex(index);
  };

  return (
    <>
      <GridWrapper>
        {photos.map(({ photo_thumbnail_url }, index) => {
          return (
            <GridItem
              key={index}
              size="default"
              onClick={() => {
                handleImageClick(index);
              }}
            >
              <GridImage src={photo_thumbnail_url} alt="Car Photo" />
            </GridItem>
          );
        })}
      </GridWrapper>

      <LightboxGallery
        slides={slides}
        currentSlideIndex={currentSlideIndex}
        setCurrentSlideIndex={setCurrentSlideIndex}
      />
    </>
  );
};
