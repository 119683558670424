import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Color } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import { useMemo } from "react";
import { LooseItemsDTO } from "shared/dto/LooseItemsDTO";
import styled from "styled-components";

const StyledTableCell = styled(TableCell)`
  &[data-color="green"] {
    background-color: ${Color.Green50};
  }
  &[data-color="red"] {
    background-color: ${Color.Red50};
  }
`;

type LooseItemsNames = {
  [T in keyof LooseItemsDTO]: string;
};

const labels: LooseItemsNames = {
  type: "Type",
  valet_keys_count: "Keys",
  remotes_count: "Remotes",
  headrests_count: "Headrests",
  is_drivable: "Drivable",
  windscreen_present: "Windscreen",
  all_glasses_ok: "Glass (all intact)",
  title_present: "Title",
  cargo_cover_present: "Cargo Cover",
  spare_tire_present: "Spare Tire",
  radio_present: "Radio",
  manuals_present: "Manual(s)",
  navigation_disk_present: "Navigation Disk",
  plugin_charger_cable_present: "Plugin Charger Cable",
  headphones_present: "Headphones",
};

const properties = Object.keys(labels).filter((x) => x !== "type") as Array<
  Exclude<keyof LooseItemsDTO, "type">
>;

export function hasLooseItem(looseItems: Partial<LooseItemsDTO>) {
  return properties.some(
    (key) => looseItems[key] !== undefined && looseItems[key] !== null,
  );
}

function formatLooseItemValue(value: boolean | number) {
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }

  if (typeof value === "number") {
    return value;
  }
}

interface Props {
  looseItems: Partial<LooseItemsDTO>;
}

export function AIAGLooseItems({ looseItems }: Props) {
  const hasAnyValue = useMemo(() => hasLooseItem(looseItems), [looseItems]);

  if (!hasAnyValue) {
    return null;
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Loose items</TableCell>
          <TableCell>Number/ Condition</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {properties.map((key) => {
          const value = looseItems[key];

          if (value == null) {
            return null;
          }

          if (typeof value === "boolean") {
            return (
              <TableRow key={key}>
                <StyledTableCell data-color={value ? "green" : "red"}>
                  {labels[key]}
                </StyledTableCell>
                <StyledTableCell data-color={value ? "green" : "red"}>
                  <TextBox
                    color={value ? "green" : "red"}
                    variant="body-semibold"
                  >
                    {formatLooseItemValue(value)}
                  </TextBox>
                </StyledTableCell>
              </TableRow>
            );
          }

          return (
            <TableRow key={key}>
              <TableCell>{labels[key]}</TableCell>
              <StyledTableCell>{formatLooseItemValue(value)}</StyledTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
